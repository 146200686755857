import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import { FluidContainer, Container } from "../components/base/Container"
import { Title } from "../components/base/Text"
import NextCourses from "../components/next/NextCourses"

const InternalContainer = styled.div`
  padding: ${props => props.theme.margins.xxl};
`

const NotFoundPage = () => (
  <Layout light>
    <FluidContainer>
      <Container>
        <InternalContainer>
          <Title size="md" color="primary500" margin="lg" black center>
            We couldn't find the page you were looking for.
          </Title>
          <NextCourses />
        </InternalContainer>
      </Container>
    </FluidContainer>
  </Layout>
)

export default NotFoundPage
