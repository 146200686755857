import styled from "styled-components"
export const FullWidthButton = styled.button`
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  margin-top: auto;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors[`${props.color}500`]};
  font-size: ${props => props.theme.fontSizes.nm};
  /* font-weight: ${props => props.theme.weights.bold}; */
  padding: ${props => props.theme.margins.lg};
  transition: all 0.15s ease-in-out;
  &:hover {
    background: ${props => props.theme.colors[`${props.color}400`]};
  }
  &:active {
    background: ${props => props.theme.colors[`${props.color}300`]};
  }
`
