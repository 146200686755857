import React from "react"
import styled from "styled-components"
import { graphql, StaticQuery, Link } from "gatsby"
import { FluidContainer } from "../base/Container"
import { Title, Subtitle } from "../base/Text"

import AngularLogo from "./AngularLogo"
import ReactLogo from "./ReactLogo"
import VueLogo from "./VueLogo"
import { getDateRange } from "../utils"
import { FullWidthButton } from "../base/Buttons"

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: ${props => props.theme.margins.xl};
  width: 100%;
  max-width: ${props => props.theme.containers.lg};
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex-direction: column;
  }
`
const CourseContainer = styled.div`
  flex: 1 1 32%;
  max-width: 32%;
  display: flex;
  flex-direction: column;
  box-shadow: ${props => props.theme.shadows.lg};
  margin-right: 2%;
  border-top: 8px solid ${props => props.theme.colors[`${props.color}400`]};
  background: white;
  &:last-of-type {
    margin-right: 0;
  }
  a {
    flex: 1 1 100%:
  }
  @media (max-width: ${p => p.theme.screen.mobile}) {
    flex: 1 1 100%;
    width: 100%;
    max-width: 100%;
    margin-bottom: ${props => props.theme.margins.lg};
    margin-right: 0;
  }
`

const Wrapper = styled.div`
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
`

const CourseInternalContainer = styled.div`
  padding: ${props => props.theme.margins.lg};
  padding-top: ${props => props.theme.margins.xl};
`

const LogoContainer = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${props => props.theme.margins.lg};
`

const ButtonContainer = styled.div`
  margin-top: auto;
  justify-self: flex-end;
`

const query = graphql`
  query {
    allContentfulCourse(
      filter: { isBookingOpen: { eq: true } }
      sort: { fields: [startDate], order: ASC }
    ) {
      edges {
        node {
          title
          startDate
          endDate
          color
          slug
          location {
            city
            country
          }
        }
      }
    }
  }
`

const logos = {
  angular: AngularLogo,
  react: ReactLogo,
  vue: VueLogo,
}

const NextCourses = props => (
  <StaticQuery
    query={query}
    render={data => {
      const courses = data.allContentfulCourse.edges
      const openCourses = courses.map(course => course.node)

      return (
        <FluidContainer>
          <Title size="xxl" color="grey900" margin="xl" black center>
            Upcoming Courses
          </Title>
          <Container>
            {openCourses.map(course => {
              const Logo = logos[course.color]

              const {
                title,
                startDate,
                endDate,
                slug,
                location: { city, country },
              } = course

              return (
                <CourseContainer
                  key={`logo-${course.color}`}
                  color={course.color}
                >
                  <Wrapper>
                    <CourseInternalContainer>
                      <LogoContainer>
                        <Logo />
                      </LogoContainer>
                      <Title size="lg" color="grey900" margin="sm" center black>
                        {title}
                      </Title>

                      <Subtitle size="sm" color="grey900" margin="lg" center>
                        {`${getDateRange({
                          startDate,
                          endDate,
                        })}, ${city}, ${country}`}
                      </Subtitle>
                    </CourseInternalContainer>

                    <ButtonContainer>
                      <Link to={`/${slug}/`}>
                        <FullWidthButton type="text" color={course.color}>
                          Learn more
                        </FullWidthButton>
                      </Link>
                    </ButtonContainer>
                  </Wrapper>
                </CourseContainer>
              )
            })}
          </Container>
        </FluidContainer>
      )
    }}
  />
)

export default NextCourses
