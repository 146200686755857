import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
  query {
    vue: file(relativePath: { eq: "logos/vue.png" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const VueLogo = () => (
  <StaticQuery
    query={query}
    render={data => <Img fixed={data.vue.childImageSharp.fixed} />}
  />
)

export default VueLogo
