import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

const query = graphql`
  query {
    react: file(relativePath: { eq: "logos/react.png" }) {
      childImageSharp {
        fixed(height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const ReactLogo = () => (
  <StaticQuery
    query={query}
    render={data => <Img fixed={data.react.childImageSharp.fixed} />}
  />
)

export default ReactLogo
